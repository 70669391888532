/* TOC – Color Variables

- Basics
- Corporate Identity Colorpalette
- Foundation Color Variables
- Grey Scale
- Topbar-Navigation
- Footer
- Code

*/

/* Basics
------------------------------------------------------------------- */

$text-color:       	#ddd;
$body-font-color: 	$text-color;
//$body-bg: 			#fdfdfd;
$body-bg: 			#333;



/* Corporate Identity Colorpalette
   https://color.adobe.com/de/Flat-Design-Colors-v2-color-theme-4341903/
-------------------------------------------------------------------

$ci-1: #334D5C;		// dark turquoise
$ci-2: #45B29D;		// turquoise
$ci-3: #EFC94C;		// yellow
$ci-4: #E27A3F;		// orange
$ci-5: #DF4949;		// red
$ci-6: #A1D044;     // green

*/
// https://color.adobe.com/ru/%D1%81%D1%87%D0%B0%D1%81%D1%82%D1%8C%D0%B5-color-theme-7111231/

$ci-1: #040B27; // navy
$ci-2: #6B8A78; // green with blue hues
$ci-4: #F3F0CD;
$ci-5: #F1DEA3; // light lemon
$ci-6: #F2B974; // light orange
/*

// dark - https://color.adobe.com/ru/Subtlety-of-Hue-The-Spa-color-theme-323417/
$ci-1: #484A47;
$ci-2: #C1CE96;
$ci-4: #ECEBF0;
$ci-5: #687D77;
$ci-6: #353129;

// bluish - https://color.adobe.com/ru/Neutral-Blue-color-theme-22361/
$ci-1: #FCFFF5;
$ci-2: #D1DBBD;
$ci-4: #91AA9D;
$ci-5: #3E606F;
$ci-6: #193441;

// bold - https://color.adobe.com/ru/Birdfolio-Blues-color-theme-1945976/
$ci-1: #2B3A42;
$ci-2: #3F5765;
$ci-4: #BDD4DE;
$ci-5: #EFEFEF;
$ci-6: #FF530D;

// balanced - https://color.adobe.com/ru/heirloom-one-color-theme-2361269/
$ci-1: #C7B773;
$ci-2: #E3DB9A;
$ci-4: #F5FCD0;
$ci-5: #B1C2B3;
$ci-6: #778691;

// raspberry - https://color.adobe.com/Dogwoods-on-a-Dreary-Day-color-theme-1781582/
$ci-1: #FCFAD0;
$ci-2: #A1A194;
$ci-4: #5B605F;
$ci-5: #464646;
$ci-6: #A90641;
*/
/* Grey Scale
------------------------------------------------------------------- */
$grey-1: #E4E4E4;
$grey-2: #D7D7D7;
$grey-3: #CBCBCB;
$grey-4: #BEBEBE;
$grey-5: #A4A4A4;
$grey-6: #979797;
$grey-7: #8B8B8B;
$grey-8: #7E7E7E;
$grey-9: #646464;
$grey-10: #575757;
$grey-11: #4B4B4B;
$grey-12: #3E3E3E;
$grey-13: #313131;
$grey-14: #242424;
$grey-15: #171717;
$grey-16: #0B0B0B;

/* Foundation Color Variables
------------------------------------------------------------------- */
$primary-color: $ci-2;
$secondary-color: $ci-6;
$alert-color: $ci-5;
$success-color: $ci-6;
$warning-color: $ci-4;
$info-color: $ci-1;

$header-font-color: $ci-6;


/* Topbar-Navigation
------------------------------------------------------------------- */
$topbar-bg-color: $body-bg;
$topbar-bg: $topbar-bg-color;

$topbar-dropdown-toggle-color: $ci-1;

$topbar-link-color: $text-color;
$topbar-link-color-hover: $ci-1;
$topbar-link-color-active: $ci-1;
$topbar-link-color-active-hover: $ci-1;

$topbar-dropdown-label-color: $ci-2;
$topbar-dropdown-link-bg-hover: $ci-6;

$topbar-link-bg-active: $ci-6; // Active Navigation Link
$topbar-link-bg-hover: $ci-6;
$topbar-link-bg-active-hover: $ci-2;

$topbar-dropdown-bg: $ci-6; // Background Mobile Navigation
$topbar-dropdown-link-color: #000;
$topbar-dropdown-link-bg: $ci-2;

$topbar-menu-link-color-toggled: $ci-1;
$topbar-menu-icon-color-toggled: $ci-1;
$topbar-menu-link-color: #000;
$topbar-menu-icon-color: #000;
$topbar-menu-link-color-toggled: $ci-6;
$topbar-menu-icon-color-toggled: $ci-6;

/* Footer
------------------------------------------------------------------- */
$footer-bg: linear-gradient(to left,$ci-2,$grey-8,$grey-8);
$footer-color: #fff;
$footer-link-color: $ci-6;

$subfooter-bg: $grey-13;
$subfooter-color: $grey-8;
$subfooter-link-color: $grey-8;

/* Code
------------------------------------------------------------------- */

$code-background-color: scale-color($secondary-color, $lightness: 70%);

$highlight-background: #ffffff;
$highlight-comment: #999988;
$highlight-error: #a61717;
$highlight-comment-special: #999999;
$highlight-deleted: #000000;
$highlight-error-2: #aa0000;
$highlight-literal-string: #d14;
$highlight-literal-number: #009999;
$highlight-name-attribut: #008080;
$highlight-error-background: #e3d2d2;
$highlight-generic-deleted: #ffdddd;
$highlight-generic-deleted-specific: #ffaaaa;
$highlight-generic-inserted: #ddffdd;
$highlight-generic-inserted-specific: #aaffaa;
$highlight-generic-output: #888888;
$highlight-generic-prompt: #555555;
$highlight-subheading: #aaaaaa;
$highlight-keyword-type: #445588;
$highlight-name-builtin: #0086B3;
$highlight-name-class: #445588;
$highlight-name-entity: #800080;
$highlight-name-exception: #990000;
$highlight-name-function: #990000;
$highlight-name-namespace: #555555;
$highlight-name-tag: #000080;
$highlight-text-whitespace: #bbbbbb;
$highlight-literal-string-regex: #009926;
$highlight-literal-string-symbol: #990073;
